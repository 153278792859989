/* eslint-disable indent */
import moment from 'moment';

import {Router} from 'aurelia-router';
import {inject, CompositionTransaction } from 'aurelia-framework';
import {App} from './app';
import {backend, user} from './services/backend';
//import {initPlaceApi} from './services/google';
import {loadScripts} from './services/loader';

import {Classes} from './services/apartment-types';
//const fancybox = require("@fancyapps/fancybox");


@inject(Router, App/*, CompositionTransaction*/)
export class ApartmentPreview {
    classes = JSON.parse(JSON.stringify(Classes));
    request = null;
    offer = null;
    data = null;

    constructor(router, app, compositionTransaction) {
    	this.router = router;
    	this.parent = app;
    	this.user = app.user;

    	/*this.compositionTransaction = compositionTransaction;
    	//this.compositionTransactionNotifier = null;

    	this.compositionTransactionNotifier = this.compositionTransaction.enlist();*/
    	loadScripts([
    	    //'https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js'
    	    '/js/jquery.fancybox.min.js'
    	]).then(() => {
    		//this.compositionTransactionNotifier.done();
    	});
    }

    activate(params) {
    	//this.page = parseInt(params.page || '1');
    	this.params = params;
    	this.parent.headerClass = 'header-details';
    	this.parent.dark = true;
		gtag('event', 'conversion', {'send_to': 'AW-11030423389/6HQdCM7_jYMYEN3O24sp'});
    }

    bind(bindingContext, overrideContext) {
    	//this.router = bindingContext.router;
    	//this.parent = bindingContext.router.container.viewModel;
    	//console.log("Offer parent, app:", this.parent, this.app);
    }

    book() {
        let offer = this.offer;
        if (!offer)
            return;

        this.paymentErrors = '';

	    if (!user.userData.mangoId) {
		    return this.router.navigateToRoute('book', {requestId: this.params.requestId, offerId: offer.id});
	    }

        return this.router.navigateToRoute('payment', {requestId: this.params.requestId, offerId: offer.id});
	    /*backend
            .service('payment')
            .create({id: offer.id, lang: this.parent.locale, url: window.location.href})
            .then(data => {
                console.log('Payment created:', data);
                if (data.error && data.error.errors)
                    this.paymentErrors = JSON.stringify(data.error.errors, 0, '\t');
                if (data.url)
                    window.location = data.url;
            });/**/
    }

    attached() {
    	if (this.params.requestId) {
    		backend.service('requests')
    			.get(this.params.requestId)
    			.then(data => {
    				console.log('Request:', data);
    				data.days = Math.abs(moment(data.checkin).diff(moment(data.checkout), 'days'));
    				this.request = data;
    			}).catch(e => console.error(e));

    		backend
    			.service('offers')
    			.get(this.params.offerId)
    			.then(data => {
    				console.log('Offer:', data.checkin, data.checkout, data);
    				data.days = moment(data.checkout).diff(moment(data.checkin), 'days');
    				data.checkin = moment(data.checkin).format('YYYY-MM-DD') + ' 14:00:00';
    				data.checkout = moment(data.checkout).set('hour', 12).format('YYYY-MM-DD hh:mm:ss');
    				this.offer = data;
    				this.data = data.apartment;
    				this.host = data.host;
    				console.log('Offer:', data.checkin, data.checkout);
    			}).catch(e => console.error(e));
    	} else if (this.params.apartmentId) {
    		backend.service('apartments')
    			.get(this.params.apartmentId)
    			.then(data => {
    				console.log('Apartment:', data);
    				//data.days = Math.abs(moment(data.checkin).diff(moment(data.checkout), 'days'));
    				this.apartment = data;
    				this.data = data.data;
    				this.host = user;
    			}).catch(e => console.error(e));
    	}
    }
}
